<template>
  <div>
    <b-card :style="'min-height: 500px; background: ' + d_treeListData.style.backGround">
      <b-row style="margin: 0px;">
        <template v-for="(video_set, video_set_ind) in d_videoSetData">
          <b-col sm="12" md="4">
            <b-card no-body header-tag="header">
              <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                <small>{{ video_set.video_title.val }}</small>
              </b-card-header>
              <b-row>
                <b-col cols="12">
                  <div class="video_mini">
                    <img @click="f_selectVideo(video_set)" :src="video_set.video_img && video_set.video_img.val ? video_set.video_img.val : d_defaultImageUrl">
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </template>
      </b-row>
    </b-card>
    <b-card style="background: black;" no-body>
      <web-bottom></web-bottom>
    </b-card>
    <b-modal v-if="d_showVideo" :title="d_selectedVideo.title" centered class="modal-success" v-model="d_showVideo" @ok="d_showVideo = false" ok-variant="success" hide-footer size="xl">
      <b-row>
        <b-col cols="12">
          <video-player class="video-player-box" ref="videoPlayer" :options="playerOptions" :playsinline="true" @ready="playerReadied">
          </video-player>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import WebBottom from '@/components/widgets/WebBottom';
import GlobalService from '@/services/global';
import { mapGetters } from 'vuex';
export default {
  name: 'Video',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    WebBottom
  },
  props: {},
  data () {
    return {
      playerOptions: {
        autoplay: false,
        controls: true,
        // height: '360',
        preload: 'auto',
        muted: false,
        language: 'en',
        playbackRates: [0.5, 0.7, 1.0, 1.2, 1.5, 2.0],
        sources: [],
        poster: "https://cdn.wisdomera.io/logo/1920-1080.png",
      },
      d_defaultImageUrl: "https://cdn.wisdomera.io/logo/1920-1080.png",
      d_showVideo: false,
      d_videoSetData: {},
      d_selectedVideo: { 'title': '', 'text': '', 'img': '' },
      d_device: { 'width': '', 'height': '' },
      d_treeListData: {
        'style': {
          'menu': {
            'background': '#ededed',
            'padding': '0',
            'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'border': 'solid 1px black'
          },
          'backGround': 'white',
          'item': {
            'background': '#ededed',
            'color': 'black',
            'margin': '3',
            'width': '300',
            'padding': '3',
            // 'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'boxShadow': '',
            'minHeight': '',
            // 'border': 'solid 1px black',
            'border': '',
            'left_plus': '10'
          }
        },
        'template': {
          'type': '2'
        },
        'list': [],
        'items': [],
        'parent_wdmr_data': '',
        'parent': { // parent and child field means: we can record a child to this parent. But other possible_relation means: this child's key can be added.
          'key': 'wdm232_2',
          'type': 'wdm232',
          'bucket': 'wisdom'
        },
        'child': {
          'reference_wdm_key': 'wdm_wisdom_wdm231_v2.0',
          'type': 'wdm231',
          'bucket': 'wisdom',
          'other_possible_relations': []
        }
      }
    };
  },
  created: function () {},
  mounted: function () {
    this.d_device.width = window.innerWidth;
    this.playerOptions.width = parseInt(this.d_device.width) / 2;
    this.f_getVideoListAndCheckQuery();
  },
  methods: {
    // listen event
    onPlayerPlay (player) {
      // console.log('player play!', player)
    },
    onPlayerPause (player) {
      // console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged (playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied (player) {
      // console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
    },
    f_getVideoListAndCheckQuery: function () {
      if (this.$route.query && this.$route.query.set && this.$route.query.key) {
        let query_set = this.$route.query.set;
        let query_key = this.$route.query.key;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Datalar hazırlanıyor' } });
        let data = { 'key': 'wdm262_1', 'bucket': 'wisdom' };
        GlobalService.get_one_cb_document(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            let vid_list_doc = resp.data.result;
            console.log(vid_list_doc);
            for (let i in vid_list_doc.data.general.set_list.list) {
              let vid_list = vid_list_doc.data.general.set_list.list[i];
              if (query_set === vid_list.set_id.val && query_key === vid_list.set_key.val) {
                this.d_videoSetData = vid_list.video_list.list;
                break;
              }
            }
            this.$forceUpdate();
          });
      }
    },
    f_selectVideo: function (video_info) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Video hazırlanıyor' } });
      this.d_showVideo = false;
      this.d_selectedVideo = { 'title': '', 'text': '', 'img': '' };
      setTimeout(function () {
        this.d_selectedVideo.title = video_info.video_title.val;
        this.d_selectedVideo.text = video_info.video_text.val;
        try {
          this.d_selectedVideo.img = video_info.video_img.val;
        } catch (err) {}
        if (this.d_selectedVideo.img !== '') {
          this.playerOptions.poster = video_info.video_img.val;
        } else {
          this.playerOptions.poster = this.d_defaultImageUrl;
        }
        this.playerOptions.sources = [];
        this.playerOptions.sources.push({
          'src': video_info.video_url.val,
          'type': 'video/' + video_info.video_type.val.value
        });
        this.d_showVideo = true;
        this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
      }.bind(this), 100)
    }
  },
  watch: {}
}

</script>

<style>
.video_mini {
  height: 240px;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.video_mini img {
  width: 100%;
  height: 100%;
  transition: 0.5s all ease-in-out;
}

.video_mini:hover img {
  transform: scale(1.2);
}

</style>

